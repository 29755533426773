import React from 'react';
import logo from './logo.svg';
import './App.css';
import VideoComp from './components/VideoComp';
import ExampleComp from './components/example';

function App() {
  return (
    <div className="App">
      <VideoComp />
      {/* <ExampleComp /> */}
    </div>
  );
}

export default App;
